const typography = {
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto, sans-serif',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    h1: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.24px',
        color: 'black',
        lineHeight: 1.3,
    },
    h2: {
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
        letterSpacing: '-0.24px',
        lineHeight: 1.3,
    },
    h3: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.06px',
        lineHeight: '20px',
    },
    h4: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '18px',
    },
    h5: {
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '15px',
    },
    h6: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '15px',
    },
    h7: {
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: '-0.05px',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '28px',
    },
    overline: {
        fontWeight: 500,
    },
    body1: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    body2: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    subtitle0: {
        fontSize: 10,
        fontWeight: 700,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    subtitle1: {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.07px',
    },
    subtitle2: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
        color: '#525252',
        lineHeight: '28px',
    },
    subtitle3: {
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
    },
    button: {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '-0.05px',
        textTransform: 'none',
    },
};

export default typography;
